// eslint-disable-next-line no-unused-vars
(function(document, undefined) {
	// eslint-disable-next-line no-undef
	const address = l18n_js_location_map_vars.marker;

	let geocoder;

	function initMap() {
		// eslint-disable-next-line no-shadow, no-undef
		const map = new google.maps.Map(
			document.getElementById('contact-map'),
			{
				zoom: 15,
				noWrap: true,
				scrollwheel: false,
				// eslint-disable-next-line no-undef
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				mapTypeControl: false,
				streetViewControl: false,
				zoomControlOptions: {
					// eslint-disable-next-line no-undef
					position: google.maps.ControlPosition.RIGHT_TOP,
				},
			}
		);
		// eslint-disable-next-line no-undef
		geocoder = new google.maps.Geocoder();
		getAddress(geocoder, map);
	}

	function getAddress(geocoder, map) {
		geocoder.geocode({ address }, function(results, status) {
			if (status === 'OK') {
				map.setCenter(results[0].geometry.location);
				// eslint-disable-next-line no-undef
				new google.maps.Marker({
					map,
					position: results[0].geometry.location,
				});
			} else {
				console.log(
					`Geocode was not successful for the following reason: ${status}`
				);
			}
		});
	}

	initMap();
})(document);
